import React, { useState, useEffect } from "react";
import "./Header.scss";
import logo from "../../Assets/logo.svg";
import { NavLink } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";

function Header() {
  const [click, setClick] = useState(false);
  const [show, setShow] = useState(false);

  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      setShow(window.pageYOffset > 100);
    };
  }, []);

  let icon;
  if (clicked) {
    icon = (
      <CloseIcon className="nav_icons" onClick={() => setClicked(!clicked)} />
    );
  } else {
    icon = (
      <MenuIcon className="nav_icons" onClick={() => setClicked(!clicked)} />
    );
  }

  return (
    <div className={`header ${show && "header_show"}`}>
      <div className="logo">
        <NavLink to="/">
          <img src={logo} alt="LOGO"></img>
        </NavLink>
      </div>
      <div className="elements">
        <div className="element">
          <NavLink to="/" onClick={() => setClick(!click)}>
            Home
          </NavLink>
          <NavLink
            to="/services"
            onClick={() => setClick(!click)}
            id="drop_down"
          >
            Services
            <div id="drop_down_1">
              <NavLink to="/services/Manpower#active_service_option_1">
                Manpower
              </NavLink>
              <NavLink to="/services/Housekeeping#active_service_option_1">
                Housekeeping
              </NavLink>
              <NavLink to="/services/Maintenance#active_service_option_1">
                Maintenance
              </NavLink>
              <NavLink to="/services/Horticulture#active_service_option_1">
                Horticulture
              </NavLink>
              <NavLink to="/services/Transportation#active_service_option_1">
                Transportation
              </NavLink>
              <NavLink to="/services#sectors">Sectors</NavLink>
            </div>
          </NavLink>
          <NavLink to="/about_us" onClick={() => setClick(!click)}>
            About Us
          </NavLink>
          <NavLink to="/gallery" onClick={() => setClick(!click)}>
            Gallery
          </NavLink>
          <NavLink to="/contact_us" onClick={() => setClick(!click)}>
            Contact
          </NavLink>
        </div>
      </div>
      <div className="header_contact">
        <NavLink to="/contact_us#Land_form" onClick={() => setClick(!click)}>
          <button class="button">Download Brochure</button>
        </NavLink>
      </div>
      <div className="logo_icon">{icon}</div>
      <div className={`close ${clicked && "open"}`}>
        <div className="pages">
          <div className="logo_half">
            <NavLink to="/">
              <img src={logo} alt="LOGO"></img>
            </NavLink>
          </div>
          <div className="page">
            <NavLink to="/" onClick={() => setClicked(!clicked)}>
              Home
            </NavLink>
          </div>
          <div className="page">
            <NavLink to="services" onClick={() => setClicked(!clicked)}>
              Services
            </NavLink>
          </div>
          <div className="page">
            <NavLink to="about_us" onClick={() => setClicked(!clicked)}>
              About Us
            </NavLink>
          </div>

          <div className="page">
            <NavLink to="contact_us" onClick={() => setClicked(!clicked)}>
              Contact
            </NavLink>
          </div>
          <div className="page">
            <NavLink to="/gallery" onClick={() => setClick(!click)}>
              Gallery
            </NavLink>
          </div>

          <NavLink
            to="/contact_us#Land_form"
            onClick={() => setClicked(!clicked)}
            style={{ color: "#000" }}
          >
            Download Brochure
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Header;
