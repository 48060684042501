import React, { useLayoutEffect } from "react";
import "./HomePage.scss";
import welcome from "../../Assets/welcome.jpg";
import TextG from "../../Components/TextG";
import whowe from "../../Assets/whowe.svg";
import { NavLink } from "react-router-dom";
import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import { Reveal, Tween } from "react-gsap";
import ArrowButton from "../../Components/ArrowButton";
import mission from "../../Assets/spacex-uj3hvdfQujI-unsplash.jpg";
import vision from "../../Assets/rodion-kutsaev-xkEtD4Stn0I-unsplash.jpg";
import logo from "../../Assets/logo1.svg";

gsap.registerPlugin(TextPlugin);

function HomePage() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="homepage">
      <div className="welcome_text">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/HomePage%2F20170927_163904.jpg?alt=media&token=caf96be7-d9aa-449d-838d-d9323d4ab578"
          alt=""
        ></img>
        <div className="mask"></div>
        <div className="text_1">
          <TextG
            dur={2}
            txt={{
              text: " Our expertise lies into indulging workers from all over INDIA.",
            }}
          >
            <h1>
              OELIIWF<span>expertise</span>workersAfromINDIA
            </h1>
          </TextG>
        </div>
        <Reveal repeat={false}>
          <Tween from={{ y: "100px" }} duration={2} ease="back.out(1.7)">
            <div className="welcome_strip">
              <div className="strip_text">
                <h1>Get A Quote For Your Project </h1>
              </div>
              <div className="strip_button">
                <a href="mailto:info@mittalservices.in">
                  {" "}
                  <button class="button">Get A Quote</button>
                </a>
              </div>
            </div>
          </Tween>
        </Reveal>
      </div>

      <div className="who_we_are">
        <Reveal repeat={false}>
          <Tween from={{ x: "-100px" }} duration={2} ease="back.out(1.7)">
            <div className="who_we_are_img">
              <img src={logo} alt="Who We are"></img>
            </div>
          </Tween>
        </Reveal>
        <Reveal repeat={false}>
          <Tween from={{ x: "200px" }} duration={2} ease="back.out(1.7)">
            <div className="who_we_are_txt">
              <h1>Who We Are</h1>
              <br />
              <br />
              <p>
                Mittal group is a leading proprietorship firm in providing
                manpower services established in 2014. We are providing a
                glossary of services such as auto ancillary, non-automotive &
                construction in all over India since 2014.Mittal Services Pvt.
                Ltd. has carried on our inheritance after 2018. Our staff is
                proficient and dedicated towards customer needs. Our key client
                includes NYK Auto Logistics, MOL Bulk Shipping, Yusen Logistic,
                Adani Ports & SEZ Ltd. etc. Our cost-effective, committed and
                dedicated services have led us to gain expertise in Gujarat.
                <br />
                <br />
                <span>
                  <NavLink to="about_us">Learn More</NavLink>
                </span>
              </p>
            </div>
          </Tween>
        </Reveal>
      </div>
      <div className="black_strip">
        <div className="black_strip_left">
          <h1>What We Provide</h1>
        </div>
        <div className="black_strip_right">
          <NavLink to="services">
            {" "}
            <ArrowButton />
          </NavLink>
        </div>
      </div>
      <div className="what_provide">
        <Reveal repeat={false}>
          <Tween
            from={{ x: "200px" }}
            stagger={0.2}
            ease="elastic.out(0.2, 0.1)"
          >
            <NavLink to="services"></NavLink>
            <div className="what_provide_odd">
              <NavLink to="services">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/HomePage%2FWhatsApp%20Image%202021-12-22%20at%2012.00.17%20AM-min.jpeg?alt=media&token=ca51c82b-8257-452b-9882-21497442327d"
                  alt=""
                ></img>
              </NavLink>

              <h1>ManPower Service</h1>
            </div>
            <div className="what_provide_even">
              <NavLink to="services">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/HomePage%2FWhatsApp%20Image%202021-12-22%20at%2012.32.37%20AM-min.jpeg?alt=media&token=52166408-5fcf-4fbd-b5ae-5fa28a518788"
                  alt=""
                ></img>
              </NavLink>
              <h1>Housekeeping Service</h1>
            </div>
            <div className="what_provide_odd">
              <NavLink to="services">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/HomePage%2FWhatsApp%20Image%202021-12-22%20at%2012.31.38%20AM-min.jpeg?alt=media&token=9cbcbf1b-39c7-40f5-bc0e-562ec03a9537"
                  alt=""
                ></img>
              </NavLink>

              <h1>Maintenance Services</h1>
            </div>
            <div className="what_provide_even">
              <NavLink to="services">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/HomePage%2FWhatsApp%20Image%202021-12-22%20at%2012.34.41%20AM-min.jpeg?alt=media&token=38928a47-d0ab-4487-9f1e-7335cab51173"
                  alt=""
                ></img>
              </NavLink>

              <h1>Horticulture Services</h1>
            </div>
          </Tween>
        </Reveal>
      </div>
      <div className="mission">
        <h1>Misison</h1>
        <Reveal repeat={false}>
          <TextG
            dur={2}
            txt={{
              text: "We are always committed for delivering the worthiest services to our clients as per their requirements",
            }}
          >
            <h2>WAcommittedclientstheir</h2>
          </TextG>
        </Reveal>
      </div>
      <div className="vision">
        <h1>Vision</h1>
        <Reveal repeat={false}>
          <TextG
            dur={2}
            txt={{
              text: "To serve our clients and the entire community with the best level of services, knowledge, professionalism, loyalty and integrity with no margin of error",
            }}
          >
            <h2>To clients community services professionalism margin</h2>
          </TextG>
        </Reveal>
      </div>
      <div className="hp_end">
        <h1>
          A creator friendly customer support team that's always just a click
          away
        </h1>
        <button class="button">
          <NavLink to="contact_us"> Contact US</NavLink>
        </button>
      </div>
    </div>
  );
}

export default HomePage;
