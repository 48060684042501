import React, { useEffect, useLayoutEffect } from "react";
import "./ContactUs.scss";
import email1 from "../../Assets/email.svg";
import phone from "../../Assets/phone.svg";
import curve from "../../Assets/curve.svg";
import abstract from "../../Assets/abstract.svg";
import contact_p from "../../Assets/Contact.svg";

import { useRef, useState } from "react";
import db from "../../Assets/sm.pdf";
import { isElement } from "react-dom/test-utils";

function ContactUs() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const checkTextInput = () => {
    if (!fname || !lname || !email) return true;
  };
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="contact_us">
      <div className="welcome_cu">
        <img src={contact_p} alt="About"></img>
      </div>
      <div className="cu_curve">
        <img src={curve} alt=""></img>
      </div>
      <div className="cu_info">
        <h1>Still have queries? Let us know</h1>
      </div>
      <div className="email_phone">
        <div className="email_cu">
          <img src={email1} alt=""></img>
          <a href="mailto:info@mittalservices.in">info@mittalservices.in</a>
        </div>
        <div className="phone_cu">
          <img src={phone} alt=""></img>

          <a href="tel:+9102717483695">+91 02717483695 </a>
        </div>
      </div>
      <div className="cu_form" id="Land_form">
        <div class="form">
          <div class="title">Write to Us</div>
          <div class="subtitle">Fill the form to Download Our Brochure</div>
          <div class="input-container ic1">
            <input
              value={fname}
              onChange={(e) => setFname(e.target.value)}
              id="firstname"
              class="input"
              type="text"
              placeholder=" "
            />
            <div class="cut"></div>
            <label for="firstname" class="placeholder">
              First name
            </label>
          </div>
          <div class="input-container ic2">
            <input
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              id="lastname"
              class="input"
              type="text"
              placeholder=" "
            />
            <div class="cut"></div>
            <label for="lastname" class="placeholder">
              Last name
            </label>
          </div>
          <div class="input-container ic2">
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              class="input"
              type="text"
              placeholder=" "
            />
            <div class="cut cut-short"></div>
            <label for="email" class="placeholder">
              Email
            </label>
          </div>

          <button class="submit" disabled={checkTextInput()}>
            <a href={db} download="Mittal-Servcies-Brochure">
              Download
            </a>
          </button>
        </div>
        <div className="form_parts">
          <div className="form_map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.9553787099617!2d72.47505781495698!3d23.02541052200015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b0ba3842e27%3A0xe4b99586f40e564e!2sOne%20World%20West!5e0!3m2!1sen!2sin!4v1632777218135!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: "0", filter: "invert(90%)" }}
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
          <div className="form_abs">
            <img src={abstract} alt=""></img>
          </div>
        </div>
      </div>
      <div className="hp_end">
        <h1>
          A creator firendly customer support team that's always just a click
          away
        </h1>
        <button class="button">
          {" "}
          <a href="mailto:info@mittalservices.in">Mail To Us</a>
        </button>
      </div>
    </div>
  );
}

export default ContactUs;
