import React from "react";
import "./Gallery.scss";
import Ps from "./Ps";

export default function Gallery() {
  const homecar = [
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F1-min.jpg?alt=media&token=f233ce72-52a0-4e05-8d86-da4354e746e4",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2FNew%2F20180808_141315-min.jpg?alt=media&token=9638da58-9ec7-444b-b0b5-1309d36b0265",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F2-min.jpg?alt=media&token=932b8e1f-0157-47f1-acd7-4849f53cfff3",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2FNew%2F20190204_152142-min.jpg?alt=media&token=b5026564-30b4-4e0f-b410-881e4ba6d208",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2FNew%2F20181119_161147-min.jpg?alt=media&token=43a5ed3b-6c89-4eef-a584-f9e2fdbd408f",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F23-min.jpg?alt=media&token=822b4161-5158-441d-869e-2080ceea0d0a",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F15-min.jpg?alt=media&token=72002f61-dce8-4b78-a5d2-c7fdfbfe31a4",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F22-min.jpg?alt=media&token=7b9921ce-e939-4195-b458-8723c8f6b657",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F26-min.jpg?alt=media&token=27e169a2-05a3-4c4c-b4d9-0e8625458f4b",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F27-min.jpg?alt=media&token=07136977-0fb9-4281-ad71-3a52e56d524d",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2FNew%2F20170927_164612-min.jpg?alt=media&token=65b6a8b8-9565-40ca-b2df-a6d55a2f6aac",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F10-min.jpg?alt=media&token=2ea1c9ea-872f-4e82-b42f-44012f725840",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F13-min.jpg?alt=media&token=56b09dc0-6f05-492b-9917-a1026209e821",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F3-min.jpg?alt=media&token=0a1d8d9a-91fd-4f4b-8938-6a5dca88b0f0",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F4-min.jpg?alt=media&token=2f85ac8a-9227-42b7-88db-fe18f8c8d7ef",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F11-min.jpg?alt=media&token=a5d53b92-61b1-4396-aadc-012cf7eab8ad",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F6-min.jpg?alt=media&token=516c3c6a-d5f1-407a-b6e0-6c3f3b57e306",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F7-min.jpg?alt=media&token=c56cf4a3-107e-4e31-961e-69e64ede4c07",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F9-min.jpg?alt=media&token=847d0335-c355-4fd7-a45f-2a9858500070",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F11-min.jpg?alt=media&token=a5d53b92-61b1-4396-aadc-012cf7eab8ad",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F12-min.jpg?alt=media&token=07cfc244-1779-4906-bbc2-248c32997380",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F8-min.jpg?alt=media&token=e551b1aa-15f5-404b-95cc-1649fc6c0238",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F9-min.jpg?alt=media&token=847d0335-c355-4fd7-a45f-2a9858500070",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F14-min.jpg?alt=media&token=dbf992f2-3efa-4770-899b-f3ede52e06b8",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F16-min.jpg?alt=media&token=3e0d3e58-f8a5-426b-8cf0-946515e4bf10",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F17-min.jpg?alt=media&token=9e795dc2-f57b-470b-b0a3-61d37055e5c0",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F18-min.jpg?alt=media&token=e428b660-b37c-45c2-9809-47ac6793b9f2",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2F21-min.jpg?alt=media&token=b8ef11d7-ffed-474d-be83-86ef74987ca6",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2FNew%2F20190204_150626-min.jpg?alt=media&token=4c18702c-2c4b-46ca-9ce4-56b3863f6a12",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2FNew%2F20190204_150604-min.jpg?alt=media&token=7a0b8976-83b2-4570-afeb-dcee4a5537ce",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2FNew%2F20181119_160130-min.jpg?alt=media&token=97e9d1f4-dcca-4856-a9e2-a2dde00d1228",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2FNew%2F20180808_141157-min.jpg?alt=media&token=ce3d8f5c-0153-41dc-8cae-2f8153d9137e",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2FNew%2F20180808_140345-min.jpg?alt=media&token=a0e95209-4e0f-4c83-806f-424e2d778996",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2FNew%2F20180808_140319-min.jpg?alt=media&token=09683ac9-f349-4276-aa91-3fa8f1eedb32",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2FNew%2F20180803_090153-min.jpg?alt=media&token=fec20c34-1056-40a6-9ba6-f691a906daf4",
    "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2FNew%2F20170927_164531-min.jpg?alt=media&token=a6728895-1afe-4695-aff3-72b92e5cbea3",
  ];

  return (
    <div>
      <Ps img={homecar} />
    </div>
  );
}
