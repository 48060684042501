import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Contact from "./Pages/ContactUs/ContactUs";
import Aboutus from "./Pages/AboutUs/AboutUs";
import Services from "./Pages/Services/Services";
import HomePage from "./Pages/HomePage/HomePage";
import Gallery from "./Pages/Gallery/Gallery";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route exact path="/contact_us">
            <Contact />
          </Route>
          <Route exact path="/services">
            <Services />
          </Route>
          <Route exact path="/services/:id">
            <Services />
          </Route>
          <Route exact path="/about_us">
            <Aboutus />
          </Route>
          <Route exact path="/gallery">
            <Gallery />
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
