import React from "react";
import "./Carousel.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";

import leftArrow from "./../../Assets/leftArrow.png";
import rightArrow from "./../../Assets/rightArrow.png";

function LeftArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      onClick={onClick}
      src={leftArrow}
      alt="arrow"
    ></img>
  );
}

function RightArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      onClick={onClick}
      src={rightArrow}
      alt="arrow"
    ></img>
  );
}

const Carousel = (props) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="services-our-clients-carousel">
      <Slider {...settings}>
        {props.clients.map((client, index) => {
          return (
            <div key={index} className="carousel-item">
              <img
                src={client}
                className="client-image"
                alt={index + " clinet image"}
              ></img>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default Carousel;
