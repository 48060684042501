import React, { useState, useEffect, useLayoutEffect } from "react";
import "./Services.scss";
import curve from "../../Assets/curve.svg";
import services_p from "../../Assets/services.svg";

import * as images from "./../../Assets/servicesImages";
import Carousel from "./Carousel";
import affordable from "../../Assets/servicesImages/Affordable.svg";
import award from "../../Assets/servicesImages/Award.svg";
import Reports from "../../Assets/servicesImages/Reports.svg";
import safety from "../../Assets/servicesImages/safety.svg";
import { useParams } from "react-router-dom";
import auto from "../../Assets/servicesImages/auto.svg";
import trans from "../../Assets/servicesImages/trans.svg";
import port from "../../Assets/servicesImages/port.svg";
import manu from "../../Assets/servicesImages/manu.svg";
import industry from "../../Assets/servicesImages/industry.svg";
import logo1 from "../../Assets/logo1.svg";

function Services(props) {
  // useLayoutEffect(() => {
  //   window.scrollTo(0, 0);
  // });

  let { id } = useParams();

  useEffect(() => {
    if (id) {
      setactiveService(id);
    }
  }, []);

  const options = [
    "Manpower",
    "Housekeeping",
    "Maintenance",
    "Horticulture",
    "Transportation",
  ];

  const content = {
    Manpower: {
      info: "We help in finding right talent for the desired requirement of a company or organization. We always make sure that our client never gets understaffed. We give our client the flexibility to choose the workers they need. We take the entire responsibility of hiring for reducing your risks of hiring the wrong candidate. Our trained workers are always competent and ready for the required job role.",

      image_1:
        "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2FNew%2F20180808_141315-min.jpg?alt=media&token=9638da58-9ec7-444b-b0b5-1309d36b0265",
    },
    Housekeeping: {
      info: "We help in transforming your office or workstation into a neat and orderly manner with an organized process. We, at Mittal Services, know that managing the whole workstation is quite tough job and it brings a lot of stress. Our housekeeping services can give your workstation a positive and astounding professional image. Our worker understands their general duties and follows all the safety protocols. They will always be available to get off the burden from you.",
      image_1:
        "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Services%2FPicture4-min.jpg?alt=media&token=af5fc045-9648-4e2b-84a3-3a37996610c3",
      image_2:
        "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Services%2FPicture5-min.jpg?alt=media&token=42814102-3f98-47a7-81f6-44c5d4bc9603",
      image_3:
        "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Services%2FPicture3-min.jpg?alt=media&token=1b096cae-10d9-4203-98b4-e09ab7552279",
    },

    Maintenance: {
      info: "We make sure to preserve the functions of the item in its operating context to the level required by the user. We maintain an item with respect to condition determined from monitored data. We follow a routine to serve the equipment so that they can work efficiently when needed. Our maintenance services include Garage maintenance, Outdoor office maintenance, Parking Maintenance etc. It also includes inspecting, cleaning, and providing minor repair services to your workstation.",
      image_1:
        "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Services%2F20190204_150604-min.jpg?alt=media&token=9c1e4c55-ce97-4457-90d7-5dc570f38ce1",
      image_2:
        "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Services%2F20170927_155911-min.jpg?alt=media&token=ca39bba1-d81b-4f9c-93de-98ef7ecf4181",
      image_3:
        "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Services%2F20180808_141315-min.jpg?alt=media&token=5713213d-34a2-4680-ab4e-97ed1bb500bc",
    },
    Horticulture: {
      info: "At Mittal services, we offer advanced horticulture services for our clients around the nation. We provide a feasibility study, design, installation supervision, as well as ongoing agronomic consultation, technical support and guidance to support changing demands. Our Horticulture Services includes Landscaping Services, Lawn maintenance, Pest & Fertilization, Snow & Rain Management, irrigation and artificial turf, Garden Development Services etc.",
      image_1:
        "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2FNew%2Fman-cutting-grass-with-lawn-mover-back-yard%20(1)-min.jpg?alt=media&token=8ed9680c-eed6-4fe7-8ef4-c32588eb50e4",
      image_2:
        "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2FNew%2Fgardeners-are-spraying-min.jpg?alt=media&token=c413d452-f531-46ef-a783-13e130cd1563",
      image_3:
        "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2FNew%2Ffarmer-spraying-vegetables-garden-with-herbicides-man-black-apron-min.jpg?alt=media&token=42715d8f-3605-4f6c-9f4d-1e5f919276ce",
    },
    Transportation: {
      info: "Our transportation services can carry the products manufactured in your workstation through all the possible methods such as road, rail, water and air as well. Our all vehicles are in maintained condition. From straight trucks both large and small, to flatbeds and trailers, we have the resources to accommodate your needs. We transport your goods in a punctual and disciplined manner. we bring our experience and expertise to add value to our customer’s value chain activities.",
      image_1:
        "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Services%2FPicture1-min.jpg?alt=media&token=310a5229-7422-4603-ae51-ae7c2f21f4fa",
      image_2:
        "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Services%2FPicture2-min.png?alt=media&token=b12f4d66-2bda-43b7-b356-829370b1567d",
      image_3:
        "https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Gallery%2FNew%2FIndian-Railways-Auto-Express_Auto-Express-train-min.jpg?alt=media&token=79eb8808-bc54-442d-893d-30f2d5e5fd92",
    },
  };

  const clients = [
    images.one,
    images.two,
    images.three,
    images.four,
    images.five,
    images.six,
    images.seven,
  ];

  const [activeService, setactiveService] = useState(id ? id : "Manpower");
  const handleServicesOption = (op) => {
    setactiveService(op);
  };

  useEffect(() => {}, [activeService]);

  return (
    <div className="services">
      <div className="welcome_services">
        <img src={services_p} alt="Logo"></img>
      </div>

      <div className="cu_curve" id="active_service_option_1">
        <img src={curve} alt=""></img>
      </div>

      <section className="services_overview" id="services_overview_1">
        <nav className="services_nav">
          {options.map((op, index) => {
            return (
              <div
                className="service_option"
                key={index}
                onClick={() => handleServicesOption(op)}
              >
                {activeService === op ? (
                  <h3 className="active_service_option">{op}</h3>
                ) : (
                  <h3>{op}</h3>
                )}
              </div>
            );
          })}
        </nav>

        {activeService ? (
          <div className="active-service-container">
            <div className="active-service-container-left">
              <h2 className="active-service-heading">{activeService}</h2>
              <p className="active-service-content">
                {content[activeService].info}
              </p>
            </div>
            <div className="active-service-container-right">
              <img
                src={content[activeService].image_1}
                alt="service-img-1"
                className="service_image_1"
              ></img>
            </div>
          </div>
        ) : null}
      </section>

      <section className="why_choose">
        <h2>Why Choose Us?</h2>
        <div className="strengths_container">
          <div className="strengths">
            <div className="strength-icon-container">
              <img src={affordable} alt="one"></img>
            </div>
            <h4>Affordable</h4>
          </div>
          <div className="strengths">
            <div className="strength-icon-container">
              <img src={award} alt="one"></img>
            </div>
            <h4>Awards</h4>
          </div>
          <div className="strengths">
            <div className="strength-icon-container">
              <img src={Reports} alt="one"></img>
            </div>
            <h4>Reports</h4>
          </div>
          <div className="strengths">
            <div className="strength-icon-container">
              <img src={safety} alt="one"></img>
            </div>
            <h4>Safety</h4>
          </div>
        </div>
      </section>

      <section className="services-our-clients-conatiner">
        <h2>Our Clients</h2>
        <Carousel clients={clients} />
      </section>
      <section className="why_choose_1" id="sectors">
        <h2>Leading Sectors</h2>
        <div className="strengths_container">
          <div className="strengths">
            <div className="strength-icon-container">
              <img src={auto} alt="one"></img>
            </div>
            <h4>Automobile</h4>
          </div>

          <div className="strengths">
            <div className="strength-icon-container">
              <img src={manu} alt="one"></img>
            </div>
            <h4>Manufacturing</h4>
          </div>

          <div className="strengths">
            <div className="strength-icon-container">
              <img src={industry} alt="one"></img>
            </div>
            <h4>Industrial</h4>
          </div>
          <div className="strengths">
            <div className="strength-icon-container">
              <img src={port} alt="one"></img>
            </div>
            <h4>Ports</h4>
          </div>
          <div className="strengths">
            <div className="strength-icon-container">
              <img src={trans} alt="one"></img>
            </div>
            <h4>Transportation</h4>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services;
