import React, { useLayoutEffect } from "react";
import "./AboutUs.scss";
import curve from "../../Assets/curve.svg";
import welcome from "../../Assets/map.svg";
import Roshit from "../../Assets/rohit.jpg";
import Arvind from "../../Assets/960.JPG";
import Vishal from "../../Assets/1627.JPG";
import Abou_p from "../../Assets/About.svg";
import { Reveal, Tween } from "react-gsap";

function About() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="about">
      <div className="welcome_au">
        <img src={Abou_p} alt="About"></img>
      </div>
      <div className="cu_curve">
        <img src={curve} alt=""></img>
      </div>
      <div className="au_info">
        {/* <div className="au_info_2">
          <img src={welcome} alt="About"></img>
        </div>
        <div className="au_info_1">
          <h1> About Our Journey</h1>
          <p>
            Mittal Services India Pvt ltd is an organization that provides a
            complete variety of service-solutions for the primary foundation of
            any firm – Manpower Solution. Our core set of services include
            Business Strategies, Process and People Outsourcing, Recruitments.
            The origin of our company dates back to some years ago. With the
            name of Mittal Group, we started the firm with a tiny office in
            Mundra, Gujarat in 2014. Though our firm gradually had its presence
            on a pan India basis. In a span of 7 years, it has set up a firm and
            steady foot in the market by benchmarking its services across all
            levels. We registered the same firm with a proper identification.
            MITTAL SERVICES INDIA PVT LTD an ISO 9001: 2015 QMS, with an aim to
            provide entire range of Manpower needs in Industrial, Manufacturing,
            Transport Management Sectors. Since its establishment, the company
            has made tremendous growth in the field of Manpower with the
            prominence of the leadership and a team of professionals. We also
            started offering other services such as Horticulture service,
            Transportation Services, Horticulture Services along with Manpower
            Services. Company is constantly endeavouring to deliver best
            Manpower results to our clientele by exceeding their expectations.
            We undertake our responsibility in strict confidence and execute the
            same to the entire satisfaction of clients.
          </p>
        </div> */}
        <div className="au_info_1">
          <h1> About Our Journey</h1>
          <p>
            <span>Mittal Services India Pvt ltd</span> is an organization that
            provides a complete variety of service-solutions for the primary
            foundation of any firm – Manpower Solution. Our core set of services
            include Business Strategies, Process and People Outsourcing,
            Recruitments. The origin of our company dates back to some years
            ago. With the name of{" "}
            <span>
              Mittal Group, we started the firm with a tiny office in Mundra,
              Gujarat in 2014.
            </span>{" "}
            Though our firm gradually had its presence on a pan India basis. In
            a span of 7 years, it has set up a firm and steady foot in the
            market by benchmarking its services across all levels. We registered
            the same firm with a proper identification.{" "}
            <span>MITTAL SERVICES INDIA PVT LTD an ISO 9001: 2015 QMS</span>,
            with an aim to provide entire range of Manpower needs in Industrial,
            Manufacturing, Warehouse Transport Management Sectors. Since its
            establishment, the company has made tremendous growth in the field
            of Manpower with the prominence of the leadership and a team of
            professionals. We also started offering other services such as ,
            <span>
              Horticulture service, Transportation Services, Horticulture
              Services along with Manpower Services .
            </span>
            Company is constantly endeavouring to deliver best Manpower results
            to our clientele by exceeding their expectations. We undertake our
            responsibility in strict confidence and execute the same to the
            entire satisfaction of clients.
          </p>
        </div>
        <img src={welcome} alt=""></img>
      </div>
      <div className="drives_us">
        <h1>What Drives Us</h1>
        <p>
          With the prominence of the leadership and a team of specialists, the
          firm has grown tremendously in the field of Manpower since its
          inception. We also began providing additional services, such as
          horticulture, transportation, and horticulture, as well as manpower
          services. Our company is continually striving to provide our clients
          with the finest Manpower outcomes possible by exceeding their
          expectations. We take our responsibilities seriously and carry them
          out to our clients' complete satisfaction.
        </p>
      </div>
      <div className="our_team_info">
        <h1>Our Experienced Team</h1>
      </div>
      <div className="our_team">
        <div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Team%2FWhatsApp%20Image%202021-12-28%20at%209.32.15%20PM.jpeg?alt=media&token=3bf9fe9b-1d26-4354-bcf9-75fdca320d9a"
            alt="Team"
          ></img>
          <h2>
            Shani Parmar
            <br />
            Managing Director
          </h2>
        </div>
        <div>
          <img
            src=" https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Team%2FWhatsApp%20Image%202022-01-20%20at%2012.18.00%20PM.jpeg?alt=media&token=2bbb37fd-1803-47e5-8335-fa7dbb30526b
"
            alt="Team"
          ></img>
          <h2>
            Mr. Manish kumar Thakur
            <br />
            Operation Head
          </h2>
        </div>
        <div>
          <img src={Arvind} alt="Team"></img>
          <h2>
            Arvindsinh Parmar
            <br />
            Field Manager
          </h2>
        </div>
        <div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/mittal-services.appspot.com/o/Team%2Frohit-min.jpg?alt=media&token=20486b3d-0f8c-48e8-a397-3d668cd92988"
            alt="Team"
          ></img>
          <h2>
            Rohit Kumar
            <br />
            Finance/Operation Head
          </h2>
        </div>
        <div>
          <img src={Vishal} alt="Team"></img>
          <h2>
            Vishalsinh Parmar
            <br />
            Site Manager
          </h2>
        </div>
      </div>
    </div>
  );
}

export default About;
