import React from "react";
import "./ArrowButton.scss";

function ArrowButton() {
  return (
    <div class="container">
      <ul>
        <li>
          <a class="animated-arrow">
            <span class="the-arrow -left">
              <span class="shaft"></span>
            </span>
            <span class="main">
              <span class="text">Explore More</span>
              <span class="the-arrow -right">
                <span class="shaft"></span>
              </span>
            </span>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default ArrowButton;
