import React from "react";
import "./Footer.scss";
import facebook from "./../../Assets/facebookIcon.svg";
import instagram from "./../../Assets/instragamIcon.svg";
import gmail from "./../../Assets/gmailIcon.svg";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-main">
        <div className="footer-main-left">
          <div className="footer-main-left-about">
            <h2 className="footer-company-title">Mittal Services Pvt Ltd</h2>
            <address>
              <span className="address-line">Address </span>
              <br></br>
              <span className="address-line">509, 5th Floor</span> <br></br>
              <span className="address-line">One word west</span>
              <br></br>
              <span className="address-line">Bopal Ambli road</span>
              <br></br>
              <span className="address-line"> Ahmedabad Gujarat 380058</span>
            </address>
          </div>
        </div>
        <div className="footer-main-middle">
          <h3>Quick Links</h3>
          <NavLink to="/" className="footer-nav-links">
            Home
          </NavLink>
          <NavLink to="services" className="footer-nav-links">
            Services
          </NavLink>
          <NavLink to="about_us" className="footer-nav-links">
            About Us
          </NavLink>
          <NavLink to="gallery" className="footer-nav-links">
            Gallery
          </NavLink>
          <NavLink to="contact_us" className="footer-nav-links">
            Contact
          </NavLink>
          <a href="/contact_us#Land_form" className="footer-nav-links">
            Download Brochure
          </a>
        </div>
        <div className="footer-main-contact">
          <h2>Contact Now</h2>

          <h3 className="footer-nav-links">
            <span className="address-line">
              {" "}
              <a href="tel:+9102717483695">+91 02717483695</a>{" "}
            </span>
          </h3>

          <h3 className="footer-nav-links">
            <span className="address-line">
              <a href="mailto:info@mittalservices.in">info@mittalservices.in</a>
            </span>
          </h3>
        </div>
        <div className="footer-main-right">
          <img src={instagram} alt="instagram"></img>
          <img src={facebook} alt="facebook"></img>
          <img src={gmail} alt="gmail"></img>
        </div>
      </div>
      <div className="final-footer">
        <h5>
          Designed and Developed by{" "}
          <a href="https://www.perfectweb.io/"> Perfect Web</a>
        </h5>
      </div>
    </div>
  );
}

export default Footer;
